<template>
  <TheHeader />
  <div class="" v-if="isLogin">
    <router-view name="a"></router-view>
  </div>
  <div v-if="showMenuPage">
    <TheNav />
    <TheName />
    <router-view v-if="showSignature"></router-view>
    <router-view v-if="showGenerator"></router-view>
    <router-view v-if="showNotification"></router-view>
    <router-view v-if="showDiscount"></router-view>
    <router-view v-if="showHomefeed"></router-view>
  </div>
</template>

<script>
import TheHeader from './components/UIComponents/TheHeader.vue';
import TheNav from '@/components/UIComponents/TheNav.vue';
import TheName from '@/components/UIComponents/TheName.vue';

export default {
  name: 'App',
  components: {
    TheHeader,
    TheNav,
    TheName
  },
  computed: {
    isLogin() {
      return this.$route.path === '/';
    },
    showSignature() {
      return this.$route.path === '/podpis';
    },
    showGenerator() {
      return this.$route.path === '/generator';
    },
    showNotification() {
      return this.$route.path === '/notifikace';
    },
    showDiscount() {
      return this.$route.path === '/slevove-kody';
    },
    showHomefeed() {
      return this.$route.path === '/homefeed';
    },
    showMenuPage() {
      if (
          this.$route.path == "/podpis"
          || this.$route.path == "/generator"
          || this.$route.path == "/notifikace"
          || this.$route.path == "/slevove-kody"
          || this.$route.path == "/homefeed"
      ) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@font-face {
  font-family: "UniSans";
  src: url("assets/fonts/UniSansRegular.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "UniSans";
  src: url("assets/fonts/UniSansBold.otf");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "UniSans";
  src: url("assets/fonts/UniSansSemiBold.otf");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "UniSans";
  src: url("assets/fonts/UniSansRegularItalic.otf");
  font-weight: normal;
  font-style: italic;
}
html,
body {
  padding: 0;
  margin: 0 auto;
  font-family: "UniSans";
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px $white inset !important;
  }
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#copytextarea {
  position: absolute;
  left: -100%;
}
</style>
