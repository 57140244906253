import { createApp } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

import router from './router';
import App from './App.vue';
import ClickOutside from './directives/clickOutside';
import AuthServiceProxy from "@/utils/auth.service";
import Config from "@/utils/config.service";

declare module "@vue/runtime-core" {
    export interface ComponentCustomProperties {
        $http: typeof axios;
    }
}

const configInstance = Config.getInstance();
configInstance.setConfigUrl("config.json");

axios.defaults.headers.common["X-Access-Token"] = "r8incg1rmxw4jc3cdyhm8h28jgfusqmp";

let app;
AuthServiceProxy.createInstance(configInstance).then(() => {
    app = createApp(App)
        .directive('detect-click', ClickOutside)
        .use(router)
        .use(VueAxios, axios)
        .mount('#app');
});
