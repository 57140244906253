<template lang="html">
  <div class="container">
    <h1 v-html="title"></h1>
    <form action="">
      <div class="section-checkbox">
        <a class="btn" @click="signIn()">Přihlásit se</a>
      </div>
    </form>
  </div>
  <div class="img" id="img"></div>
</template>

<script>
import AuthServiceProxy from "@/utils/auth.service";

export default {
  name: 'Login',
  created() {
    if (AuthServiceProxy.getUser() !== null) {
      if (AuthServiceProxy.getRoles() === null) {
        alert("Nemáte přidělena žádná oprávnění!");
      } else {
        this.$router.push({ name: 'TheNotification' });
      }
    }
  },
  data() {
    return {
      title: 'Vstup do systému<br /><span>Zásilkovna MKT</span>',
      firstInput: 'E-mail',
      secondInput: 'Heslo',
      thirdInput: 'Zapamatovat si údaje',
      userData: {
        email: null,
        emailValidity: 'pending',
        password: '',
        passwordValidity: 'pending',
      },
      errorMsgEmail: 'Nesprávný formát e-mailové adresy',
      errorMsgPassword: 'Heslo je nesprávné',
    };
  },
  methods: {
    async signIn() {
      const user = await AuthServiceProxy.loginPopup();
      await AuthServiceProxy.setUser(user);
      AuthServiceProxy.loginRedirect();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.container {
  padding: 120px $pad-side 0 $pad-side;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 75vh;
  margin: 0 auto;
  h1 {
    font: normal normal 3.5rem/normal UniSans;
    letter-spacing: normal;
    text-align: left;
    color: $black;
    ::v-deep(span) {
      font-weight: 600;
      color: $red;
    }
  }
  form {
    max-width: 465px;
    .wrapper {
      .text {
        font: normal normal .875rem/normal Roboto;
        letter-spacing: 0.16px;
        text-align: left;
        color: $darkergray;
        margin-bottom: .875rem;
        display: block;
      }
      .group {
        display: flex;
        position: relative;
        &:focus-within {
          filter: drop-shadow(
                  0 0 7px rgba( 0, 0, 0, .15)
          );
        }
        .input-border {
          display: inline-block;
          position: relative;
          background: $gray;
          width: 100%;
          height: calc(3.125rem + 2px);
          box-sizing: border-box;
          line-height: normal;
          -webkit-clip-path: polygon(
                  0 6px,
                  6px 0,
                  0 0,
                  100% 0,
                  100% calc(100% - 6px),
                  calc(100% - 6px) 100%,
                  0 100%,
                  0 0
          );
          clip-path: polygon(
                  0 6px,
                  6px 0,
                  0 0,
                  100% 0,
                  100% calc(100% - 6px),
                  calc(100% - 6px) 100%,
                  0 100%,
                  0 0
          );
          input[type=email],
          input[type=password] {
            font: normal 500 16px/1.2 Roboto;
            font-stretch: normal;
            letter-spacing: 0.16px;
            text-align: left;
            color: $black;
            position: absolute;
            width: calc(100% - 2px);
            top: 1px;
            left: 1px;
            border-style: none;
            height: 3.125rem;
            padding-left: 20px;
            padding-right: 20px;
            box-sizing: border-box;
            outline: none;
            -webkit-clip-path: polygon(
                    0 6px,
                    6px 0,
                    0 0,
                    100% 0,
                    100% calc(100% - 6px),
                    calc(100% - 6px) 100%,
                    0 100%,
                    0 0
            );
            clip-path: polygon(
                    0 6px,
                    6px 0,
                    0 0,
                    100% 0,
                    100% calc(100% - 6px),
                    calc(100% - 6px) 100%,
                    0 100%,
                    0 0
            );
          }
          &.invalid,
          &.invalidPassword {
            background: $red;
            &::after {
              content: '';
              position: absolute;
              background: url('~@/assets/icons/error.svg') no-repeat;
              width: 10px;
              height: 10px;
              right: 20px;
              top: 20px;
            }
          }
        }
      }
      .custom-text {
        font: normal 500 .75rem/normal Roboto;
        font-style: normal;
        letter-spacing: 0.12px;
        text-align: right;
        color: $red;
        font-weight: 500;
        padding: 0 1rem 0 1rem;
        display: block;
        opacity: 1;
        margin-top: .5rem;
        height: 14.4px;
      }
    }
    .section-checkbox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 2.4375rem;
      label {
        position: relative;
        cursor: pointer;
        input[type=checkbox] {
          cursor: pointer;
          &:checked {
            ~ .checkmark {
              background-color: $white;
              &:after {
                display: block;
              }
            }
          }
        }
        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 18px;
          width: 20px;
          background-color: $white;
          border: 1px solid $gray;
          &::after {
            content: "";
            position: absolute;
            display: none;
            left: 7px;
            top: 2px;
            width: 3px;
            height: 10px;
            border: solid $black;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
      .text-checkbox {
        font: normal normal .875rem/1.2 Roboto;
        margin-left: -3rem;
        letter-spacing: 0.16px;
        text-align: left;
        color: $darkergray;
      }
      .btn {
        color: $white;
        text-decoration: none;
        background-color: $red;
        width: 100%;
        height: 46px;
        padding: 0;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        max-width: 220px;
        cursor: pointer;
        position: relative;
        outline: none;
        border: none;
        align-items: center;
        justify-content: center;
        display: flex;
        &:hover {
          background-color: $darkred;
        }
        -webkit-clip-path: polygon(
                0 6px,
                6px 0,
                0 0,
                100% 0,
                100% calc(100% - 6px),
                calc(100% - 6px) 100%,
                0 100%,
                0 0
        );
        clip-path: polygon(
                0 6px,
                6px 0,
                0 0,
                100% 0,
                100% calc(100% - 6px),
                calc(100% - 6px) 100%,
                0 100%,
                0 0
        );
      }
    }
  }
}
.img {
  position: absolute;
  bottom: 0;
  right: 0;
  background: url('~@/assets/photos/landscape.png') no-repeat;
  display: none;
}
@media screen and (min-width: 1090px) {
  .img {
    width: 50%;
    height: 100vh;
    background-size: 100% 100%;
    z-index: 24;
    display: block;
  }
}
@media screen and (min-width: 1400px) {
  .container {
    padding: 120px $pad-side-l 0 $pad-side-l;
  }
}
@media screen and (min-width: 2000px) {
  .container {
    max-width: 1400px;
  }
}
</style>
