import { createRouter, createWebHistory } from 'vue-router';
import MsLogin from '@/components/Login/MsLogin.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'TheLogin',
      components: {
        a: MsLogin
      },
    },
    {
      path: '/notifikace',
      name: 'TheNotification',
      component: () =>
        import(
          /* webpackChunkName: 'Notification' */ '@/components/Pages/TheNotification.vue'
      ),
    },
    {
      path: '/slevove-kody',
      name: 'TheDiscount',
      component: () =>
        import(
          /* webpackChunkName: 'Discount' */ '@/components/Pages/ThePromocode.vue'
      ),
    },
    {
      path: '/homefeed',
      name: 'TheHomefeed',
      component: () =>
          import(
              /* webpackChunkName: 'Homefeed' */ '@/components/Pages/TheHomefeed.vue'
              ),
    }
  ]
});

export default router;
