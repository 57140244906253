import * as Msal from 'msal'
import {UserAgentApplication} from "msal";
import {AxiosRequestConfig } from "axios";
import Config from "@/utils/config.service";

export interface AuthConfiguration {
    apiBaseUrl: string;
    azureAdClientId: string;
    azureAdAuthority: string;
    azureAdScope: string[];
}

interface AuthServiceInterface {
    applicationConfig: AuthConfiguration;
    app: UserAgentApplication;

    getApiBaseUrl(): string;
    loginPopup(): void;
    logout(): void;
    loginRedirect(): void;
    getRoles(): any;
    hasRole(role: string): boolean;
    getUser(): any;
    getToken(): Promise<string | null>;
    getAuthentication(): Promise<AxiosRequestConfig>;
    isAuthenticated(): boolean;
}

export class AuthService implements AuthServiceInterface {
    public applicationConfig: AuthConfiguration;
    public app: UserAgentApplication;

    private token: string | null;
    private user: any;

    constructor(config: AuthConfiguration) {
        this.token = null;
        this.user = null;
        this.applicationConfig = config;
        const redirectUri = window.location.origin;
        this.app = new Msal.UserAgentApplication(
            this.applicationConfig.azureAdClientId,
            this.applicationConfig.azureAdAuthority,
            (errorDesc: string, token: string, error: string, tokenType: string, userState: string) => {
                this.processCallback(errorDesc, token, error, tokenType, userState);
            },
            {
                redirectUri: redirectUri,
                cacheLocation: "localStorage",
                storeAuthStateInCookie: true
            }
        )
    }

    public getApiBaseUrl(): string
    {
        return this.applicationConfig.apiBaseUrl;
    }

    public static async createInstance(configInstace: Config): Promise<AuthService> {
        const configJson = await configInstace.getConfiguration();
        const config: AuthConfiguration = {
            apiBaseUrl: configJson.azureAd.apiBaseUrl,
            azureAdClientId: configJson.azureAd.azureAdClientId,
            azureAdAuthority: configJson.azureAd.azureAdAuthority,
            azureAdScope: [ configJson.azureAd.azureAdScope ]
        };
        return new AuthService(config);
    }

    processCallback(errorDesc: string, token: string, error: string, tokenType: string, userState: string): void {
        console.log("Got token type: " + tokenType);
    }

    async getAuthentication(): Promise<AxiosRequestConfig> {
        return {
            headers: {
                "Authorization": "Bearer " + await this.getToken(),
            },
            withCredentials: true
        };
    }

    isAuthenticated(): boolean {
        return this.getUser() !== null;
    }

    // Core Functionality
    async loginPopup() {
        return this.app.loginPopup(this.applicationConfig.azureAdScope).then(
            () => {
                const user = this.app.getUser();
                if (user) {
                    return user;
                } else {
                    return null;
                }
            },
            () => {
                return null;
            }
        );
    }

    loginRedirect() {
        this.app.loginRedirect(this.applicationConfig.azureAdScope)
    }

    logout() {
        this.app.logout()
    }

    // Graph Related
    async getGraphToken(): Promise<string | null> {
        return await this.app.acquireTokenSilent(
            this.applicationConfig.azureAdScope,
            this.applicationConfig.azureAdAuthority,
            this.app.getUser()
        ).then(
            (accessToken: any) => {
                return accessToken
            },
            (error: any) => {
                return this.app
                    .acquireTokenPopup(
                        this.applicationConfig.azureAdScope,
                        this.applicationConfig.azureAdAuthority
                    )
                    .then(
                        (accessToken: any) => {
                            return accessToken
                        },
                        (err: any) => {
                            console.log("Error: " + err);
                            return null;
                        }
                    )
            }
        )
    }

    async getToken(): Promise<string | null> {
        return await this.getGraphToken();
    }

    async setUser(user: any) {
        this.user = user;
    }

    // Utility
    getUser() {
        if (this.user === null) {
            this.user = this.app.getUser();
        }
        return this.user;
    }

    getRoles()
    {
        if (this.getUser() !== null) {
            const userData: any = JSON.parse(JSON.stringify(this.getUser()));
            let roles: string[] | null = null;
            if (userData.idToken !== undefined) {
                roles = userData.idToken.roles;
            }
            return (roles !== undefined && roles !== null && Array.isArray(roles) && roles.length > 0) ? roles : null;
        }
        return null;
    }

    hasRole(role: string): boolean {
        if (this.getUser() !== null) {
            const roles = this.getRoles();
            return (roles !== undefined && roles !== null && Array.isArray(roles) && roles.includes(role));
        }
        return false;
    }

    mustHaveRole(role: string, signOut = false) {
        const hasRole: boolean = this.hasRole(role);
        if (!hasRole && signOut) {
            //alert("You account does not have required permission: " + role);
            //this.logout();
        }
        return hasRole;
    }
}

export default class AuthServiceProxy {
    public static authService: AuthService;

    public static async createInstance(configInstance: Config): Promise<boolean> {
        AuthServiceProxy.authService = await AuthService.createInstance(configInstance);
        return true;
    }

    public static getApiBaseUrl(): string {
        return this.authService.getApiBaseUrl();
    }

    public static getUser(): any {
        return this.authService.getUser();
    }

    public static async getGraphToken() {
        return await this.authService.getGraphToken();
    }

    public static async getToken() {
        return await this.authService.getToken();
    }

    public static async loginPopup() {
        return await this.authService.loginPopup();
    }

    public static loginRedirect(): void {
        return this.authService.loginRedirect();
    }

    public static logout(): void {
        return this.authService.logout();
    }

    public static getRoles() {
        return this.authService.getRoles();
    }

    public static hasRole(role: string): boolean {
        return this.authService.hasRole(role);
    }

    public static mustHaveRole(role: string, signOut = false): boolean {
        return this.authService.mustHaveRole(role, signOut);
    }

    public static async getAuthentication(): Promise<AxiosRequestConfig> {
        return await this.authService.getAuthentication();
    }

    public static isAuthenticated(): boolean {
        return this.authService.isAuthenticated();
    }

    public static async setUser(user: any) {
        return await this.authService.setUser(user);
    }

    public static async authenticate() {
        const user = await AuthServiceProxy.loginPopup();
        await AuthServiceProxy.setUser(user);
        AuthServiceProxy.loginRedirect();
        return true;
    }
}
