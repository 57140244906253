<template lang="html">
  <div class="container">
    <p>{{ userName }}</p>
    <div class="dropdown" v-detect-click="toggleInputOutside">
      <div class="icon icon-1" @click="toggleDropdown"></div>
      <div class="dropdown-content" v-if="visible">
        <!--
        <a @click="toggleLanguage" href="#" class="link first">Jazyk<span>Čeština</span></a>
        <transition name="menu-down">
          <div v-if="showLanguage">
            <div class="divider"></div>
            <a href="#" class="link middle">Čeština</a>
            <div class="divider"></div>
            <a href="#" class="link middle">Angličtina</a>
            <div class="divider"></div>
            <a href="#" class="link middle">Němčina</a>
            <div class="divider"></div>
            <a href="#" class="link middle">Polština</a>
            <div class="divider"></div>
            <a href="#" class="link middle">Slovenština</a>
          </div>

        </transition>
        -->
        <div class="divider"></div>
        <a class="link last" @click="signOut">Odhlásit se <img src="@/assets/icons/lock.svg" alt="Odhlásit se" title="Odhlásit se" /></a>
      </div>
    </div>
  </div>
</template>

<script>
import AuthServiceProxy from "@/utils/auth.service";

export default {
  name: 'Name',
  async created() {
    if (AuthServiceProxy.getUser() === null) {
      this.$router.push({ name: 'TheLogin' });
    } else {
      if (AuthServiceProxy.getRoles() === null) {
        alert("Nemáte přidělena žádná oprávnění!");
      }
    }
  },
  data() {
    return {
      userName: (this.getUser() === null) ? null : this.getUser().displayableId,
      showLanguage: false,
      visible: false
    };
  },
  methods: {
    getUser() {
      return AuthServiceProxy.getUser();
    },
    async signOut() {
      AuthServiceProxy.logout();
    },
    toggleDropdown() {
      this.visible = !this.visible;
    },
    toggleInputOutside() {
      this.visible = false;
    },
    toggleLanguage() {
      this.showLanguage = !this.showLanguage;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
@keyframes menu-down {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}
.container {
  padding: 10px $pad-side 0 $pad-side;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 90%;
  z-index: 24;
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  p {
    display: flex;
    align-items: center;
    margin: 0;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $black;
    margin-right: 13px;
    height: 30px;
  }
  .dropdown {
    position: relative;
    .icon {
      background: url("~@/assets/icons/user.svg") center no-repeat;
      background-size: 30%;
      width: 30px;
      height: 30px;
      background-color: $red;
      position: relative;
      cursor: pointer;
      -webkit-clip-path: polygon(
        0 4px,
        4px 0,
        0 0,
        100% 0,
        100% calc(100% - 4px),
        calc(100% - 4px) 100%,
        0 100%,
        0 0
      );
      clip-path: polygon(
        0 4px,
        4px 0,
        0 0,
        100% 0,
        100% calc(100% - 4px),
        calc(100% - 4px) 100%,
        0 100%,
        0 0
      );
    }
    .dropdown-content {
      position: absolute;
      right: -12px;
      top: 55px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,10%);
      z-index: 1;
      .link {
        height: 25px;
        padding: 12px 16px;
        text-decoration: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font: normal normal 14px/normal Roboto;
        text-align: left;
        color: $darkgray;
        background-color: $white;
        &.first {
          border-color: $gray;
          border-style: solid;
          border-width: 1px 1px 0 1px;
          position: relative;
          height: 24px;
          &::after,
          &::before {
            content: '';
            display: block;
            position: absolute;
            top: -39.7%;
            width: 0;
            height: 0;
            border-style: solid;
          }
          &::after {
            left: 122px;
            border-color: transparent transparent $white transparent;
            border-width: 10px;
          }
          &::before {
            left: 121px;
            top: -46%;
            border-color: transparent transparent  $gray transparent;
            border-width: 11px;
          }
          &:hover::after {
            border-color: transparent transparent $bg-gray transparent;
          }
        }
        &.middle {
          border-color: $gray;
          border-style: solid;
          border-width: 0 1px 0 1px;
        }
        &.last {
          border-color: $gray;
          border-style: solid;
          border-width: 0 1px 1px 1px;
          height: 24px;
        }
        &:hover {
          background-color: $bg-gray;
          font-weight: 500;
        }
        span {
          font: normal 500 14px/normal Roboto;
          letter-spacing: normal;
          color: $darkgray;
        }
      }
      .divider {
        height: 1px;
        width: 160px;
        background-color: $gray;
      }
      .menu-down-enter-active {
        animation: menu-down .5s ease-in-out;
        transform-origin: top center;
      }
      .menu-down-leave-active {
        animation: menu-down .5s ease-out;
        animation-direction: alternate-reverse;
        transform-origin: top center;
      }
    }
  }
}
@media screen and (min-width: 1090px) {
  .container {
    padding: 20px $pad-side 10px $pad-side;
    p {
      font-size: 16px;
      margin-right: 16px;
    }
    .icon {
      width: 36px;
      height: 36px;
      background-size: initial;
    }
  }
}
@media screen and (min-width: 1400px) {
  .container {
    padding: 20px $pad-side-l 10px $pad-side-l;
  }
}
@media screen and (min-width: 2000px) {
  .container {
    max-width: 1400px;
  }
}
@media screen and (min-width: 2800px) {
  .container {
    bottom: 95%;
  }
}
</style>
