export default class Config {
    private static instance: Config;

    private configUrl: string | null;
    private configuration: any;

    constructor() {
        this.configUrl = null;
    }

    public static getInstance(): Config {
        if (this.instance === undefined) {
            this.instance = new Config();
        }
        return this.instance;
    }

    setConfigUrl(url: string): void {
        this.configUrl = url;
    }

    async getConfiguration() {
        if (this.configuration !== undefined) {
            return this.configuration;
        }

        if (this.configUrl !== null) {
            const response = await fetch(this.configUrl);
            this.configuration = await response.json();
            return this.configuration;
        }

        return null;
    }
}