<template lang="html">
  <section>
    <div class="container">
      <div class="nav">
        <router-link :to="router.nav" v-for="router in routers" :key="router">
          <div :class="router.icon"></div>
          <span>{{ router.title }}</span>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import AuthServiceProxy from "@/utils/auth.service";
import Roles from "@/models/roles.model";

export default {
  name: 'Navigation',
  data() {
    return {
      routers: this.getNavigation(),
    };
  },
  methods: {
    getNavigation() {
      let navigationSections = [
        {
          nav: '/notifikace',
          icon: 'icon icon-4',
          title: 'Notifikace',
          role: Roles.ROLE_NOTIFICATION_SEND
        },
        {
          nav: '/slevove-kody',
          icon: 'icon icon-5',
          title: 'Slevové kódy',
          role: Roles.ROLE_PROMOCODES_MANAGING
        },
        {
          nav: '/homefeed',
          icon: 'icon icon-3',
          title: 'Správa homefeedu',
          role: Roles.ROLE_HOMEFEED_MANAGING
        },
      ];

      let availableSections = [];
      navigationSections.forEach((item) => {
        if (AuthServiceProxy.hasRole(item.role)) {
          availableSections.push(item);
        }
      });

      return availableSections;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
section {
  margin: 0 auto;
  padding: 80px 0 0 $pad-side;
  .container {
    .nav {
      display: flex;
      position: absolute;
      flex-direction: column;
      background-color: $bg-gray;
      width: 123px;
      padding: 0 8px 20px 12px;
      -webkit-clip-path: polygon(0 6px,6px 0,0 0,100% 0,100% calc(100% - 6px),calc(100% - 6px) 100%,0 100%,0 0);
      clip-path: polygon(0 6px,6px 0,0 0,100% 0,100% calc(100% - 6px),calc(100% - 6px) 100%,0 100%,0 0);
      a {
        text-decoration: none;
        color: inherit;
        display: flex;
        margin-top: 20px;
        &.router-link-active span {
          color: $black;
        }
        &.router-link-active .icon {
          filter:
            invert(64%)
            sepia(80%)
            saturate(9000%)
            hue-rotate(25deg);
        }
        .icon {
          width: 18px;
          height: 18px;
          margin-right: 10px;
          &.icon-1 {
            background: url("~@/assets/icons/e-mail.svg") no-repeat;
            background-size: contain;
          }
          &.icon-2 {
            background: url("~@/assets/icons/card.svg") no-repeat;
            background-size: contain;
          }
          &.icon-3 {
            background: url("~@/assets/icons/blog.svg") no-repeat;
            background-size: contain;
          }
          &.icon-4 {
            background: url("~@/assets/icons/notification.svg") no-repeat;
            background-size: contain;
          }
          &.icon-5 {
            background: url("~@/assets/icons/code.svg") no-repeat;
            background-size: contain;
          }
        }
        &:not(.router-link-active) {
          &:hover span:before {
            transform: translateX(0%);
          }
        }
        span {
          position: relative;
          display: inline-block;
          overflow: hidden;
          font: normal 500 14px/normal Roboto;
          letter-spacing: normal;
          text-align: left;
          color: #d0d0d0;
          display: flex;
          align-items: center;
          &:before,
          &:after {
            content: '';
            position: absolute;
            transition: transform .5s ease-out;
          }
          &:before {
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background-image: linear-gradient(to right, $white , $red);
            transform: translateX(-101%);
          }
          a {
            margin: 0;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1090px) {
  section {
    padding: 125px 0 0 $pad-side;
    .container {
      .nav {
        width: 262px;
        padding: 20px 0 40px 24px;
        a {
          .icon {
            width: 32px;
            height: 32px;
            margin-right: 24px;
          }
          span {
            font-size: 20px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1400px) {
  section {
    padding: 125px $pad-side-l 0 $pad-side-l;
  }
}
@media screen and (min-width: 2000px) {
  section {
    max-width: 1400px;
  }
}
</style>
